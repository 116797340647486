<template>
    <div class="view_container">

        <div id="content_main">
            <div id="category_content">
                <div id="category_content_title">
                    {{ languageData.datumi_naslov }}
                </div>

                <table class="cart_item_price">
                    <tr>
                        <td class="cart_item_price_cell_text">{{ languageData.datumi_izbira }}: {{ getSelectedDatesStr }} </td>
                        <td class="cart_item_price_cell" style=""><span @click="truncateSelectedDates()">X</span></td>
                    </tr>
                </table>

            </div>

            <div id="offer_content">
                <div id="offer_content_title">
                    {{ languageData.datumi_podnaslov }}
                </div>
                
                <date-item :data="item" v-for="(item, index) in getDates" :key="index"></date-item>
            </div>


        </div>

        <div id="content_footer">
            <div class="footer_btn_item">
                <button class="btn_footer btn_footer_red" @click="goBack()"><i id="footer_btn_position_left" class="fas fa-times"></i>&nbsp;&nbsp;{{ languageData.nazaj }}</button>
            </div>
            <div class="footer_btn_item">
                <table id="table_amount" width="100%;" height="92px;">
                    <tbody>
                        <tr id="title">
                            <td>
                                {{ languageData.skupaj_za_placilo }}
                            </td>
                        </tr>
                        <tr id="amount">
                            <td>
                                <!--<transition name="fade" mode="out-in">
                                    <div :key="getCartItemsTotal">
                                        {{ getCartItemsTotal }}<span class="currency">€</span>
                                    </div>
                                </transition>-->
                                {{ getCartItemsTotal }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="footer_btn_item">
                <button class="btn_footer btn_footer_green" @click="goToCart()">{{ languageData.nadaljuj }} <i id="footer_btn_position_right" class="fas fa-chevron-right"></i></button>
            </div>
        </div>

    </div>
</template>

<script>
import { formatPrice } from '@/helpers/utilities.js'
import DateItem from '@/components/DateItem.vue'
import { emitter } from '@/main.js'

export default {
    components: {
        DateItem
    },

    data: () => ({
        sum: 0.00,
        category_title: "Izberi datum obiska soteske",
        offer_title: "Izberite daum vstopa",
    }),

    computed: {
        getSum() {
            return this.sum.toFixed(2).toLocaleString('pt-BR')
        },

        getCartItemsTotal() {
            //console.log(this.$store.getters.price)
            //var number = 350.0;
            //console.log("Aggregated");
            //console.log(this.$store.getters.cartItemsAggregated);
            //console.log("=================================")


            //console.log(new Intl.NumberFormat('de-DE').format(number));
            return formatPrice(this.$store.getters.price)
            //return new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 2, minimumFractionDigits: 2 }).format(this.$store.getters.price)
        },

        languageData() {
            return this.$store.getters.languageData
        },

        getDates() {
            return this.$store.getters.dates
        },

        getSelectedDatesStr() {
            let selectedDates = this.$store.getters.selectedDates
            let str = ""

            if(selectedDates.length > 0) {
                let sortedDates = selectedDates.sort((a,b) => (a.timestamp > b.timestamp) ? 1 : -1)
                //window.console.log("xxx");
                //window.console.log(selectedDates)

                if(this.$store.getters.language == 'sl')
                {
                    selectedDates.forEach(date => {
                        if(date != null && Object.prototype.hasOwnProperty.call(date, "id")) {
                            if(date.id == 1) {
                                str  += date.day_name + ", " + date.date_formatted + " (danes)" + ", "
                            }
                            else {
                                str += date.day_name + ", " + date.date_formatted + ", "
                            }
                        }
                        
                    })  
                } else {
                    selectedDates.forEach(date => {
                        if(date != null && Object.prototype.hasOwnProperty.call(date, "id")) {
                            if(date.id == 1) {
                                str  += date.day_name_en + ", " + date.date_formatted + " (today)" + ", "
                            }
                            else {
                                str += date.day_name_en + ", " + date.date_formatted + ", "
                            }
                        }
                        
                    })
                }
                
                
                str = str.replace(/,\s*$/, "")
            }

            return str
        }
    },

    methods: {
        goBack() {
            //izprazni še košarico
            //this.$store.dispatch('EMPTY_CART');
            return this.$router.push({
                name: 'Store'
            })
        },

        goToCart() {
            this.$router.push({
                name: 'Cart'
            })
        },

        truncateSelectedDates() {
            //window.console.log("truncating selected dates....")
            this.$store.dispatch('TRUNCATE_SELECTED_DATES')
            emitter.emit('TRUNCATE_SELECTED_DATES')
        }
    },

    created() {
        //če slučajno ni izbranega datuma ... nastaviš začetni datum
        if(this.$store.getters.selectedDates.length == 0) {
            this.$store.dispatch('ADD_DATE', this.$store.getters.dates[0])
        }
    },

    mounted() {

    },

    unmounted() {
        //window.console.log("mounted :)")
    }

}

</script>

<style scoped>
    #table_amount #title {
        
        text-align:center;
        font-size:18px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        color: #656565;
        font-weight:600;
    }

    #table_amount #amount {
        text-align:center;
        font-size:38px;
        color: #007CA4;
        font-weight:500;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    #table_amount {
        background: #ECECEC;
    }

    #category_content {
        height:20vh;
        /*border: 1px solid purple;*/
        margin:5px;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        /*justify-content: space-between;*/
        justify-content: center;
    }

    #offer_content {
        height:53vh;
        /*border: 1px solid green;*/
        margin:5px;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        /*justify-content: space-between;*/
        justify-content: space-between;
        align-items:flex-start;
    }

    #category_content_title {
        width: 100%;
        text-align:center;
        font-size:18px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        font-weight:600;
    }

    #offer_content_title {
        width: 100%;
        text-align:center;
        font-size:18px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        font-weight:600;
        height:30px;
        border-bottom: 1px solid #007CA4;
        
    }

    .offer_item {
        height:113px;
        width: 30%;
        border:1px solid #8E8E8E;
        margin-top:-70px;
    }

    .offer_item_content {
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        width:100%;
        border-left: 5px solid #007ca4;
        border-right: 1px solid #8E8E8E;
        border-top: 1px solid #8E8E8E;
        border-bottom: 1px solid #8E8E8E;
    }

    .offer_item_title {
        width:100%;
        text-align:center;
        padding-top:10px;
        font-size:16px;
        font-weight:600;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    .category_item_name {
        width:100%;
        text-align:center;
        padding-top:5px;
        font-size:16px;
        font-weight:500;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    .offer_item_price {
        width:100%;
        text-align: center;
        padding-top:15px;
        font-size:20px;
        font-weight:600;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        color: #007CA4;
    }
    
    .currency {
        margin-left: 3px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    #footer_btn_position_right {
        /*position:relative;
        right: -50px;*/
    }

    #footer_btn_position_left {
        /*position:relative;
        right: 30px;*/
    }

    #content_main {
        min-height:76vh;
        /*border:1px solid orange;*/
        margin:5px;
    }

    .cart_item_price {
        width:100%;
        /*margin-top:30px;*/
        border-left: 5px solid #007ca4; 
        border-right: 1px solid #A3A7A5;
        border-top: 1px solid #A3A7A5;
        border-bottom: 1px solid #A3A7A5;
        height:113px;
    }

    .cart_item_price td {
        text-align:left;
        padding-top:20px;
        padding-bottom: 20px;
        padding-left:10px;
        padding-right:10px;
    }

    .cart_item_price_cell_text {
        width:75%;
        font-size:20px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        font-weight:600;
    }

    .cart_item_price_cell {
        width:25%;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        font-weight:600;
        color: #007CA4;
        font-size:22px;
        text-align:right !important;
    }
</style>