<template>
    <div v-wave="getRippleData()" class="date_item" :id="'date_item_' + data.id" @click="selectItem()">
        <div class="date_item_content">
            <div class="date_item_title">
                {{ name }}
            </div>
        </div>
    </div>
</template>

<script>
import Ripple from './Ripple.vue'
import { hexToRgba } from '@/helpers/utilities.js'
import { emitter } from '@/main.js'

export default {
    name: 'date-item',
    props: ['data'],

    data: () => ({
        clicked: false
    }),

    watch: {
        clicked(value) {
            if(value === true) {
                document.querySelector("#date_item_" + this.data.id).style.background = "rgba(142, 142, 142, 0.2)";
            } else {
                document.querySelector("#date_item_" + this.data.id).style.background = "#fff";
            }
        }
    },

    computed: {
        name() {
            if(this.$store.getters.language == 'sl') {
                if(this.data.id == 1) {
                    return this.data.day_name.charAt(0).toUpperCase() + this.data.day_name.slice(1) + ", " + this.data.date_formatted + " (danes)"
                }

                return this.data.day_name.charAt(0).toUpperCase() + this.data.day_name.slice(1) + ", " + this.data.date_formatted  
            }

            if(this.data.id == 1) {
                return this.data.day_name_en.charAt(0).toUpperCase() + this.data.day_name_en.slice(1) + ", " + this.data.date_formatted + " (danes)"
            }

            return this.data.day_name_en.charAt(0).toUpperCase() + this.data.day_name_en.slice(1) + ", " + this.data.date_formatted
        },

        
    },

    methods: {
        selectItem() {
            //preveriš če je izbran samo 1 datum in če je id izbranega datuma enak id-ju komponente ... preprečiš odstranitev tega edinega datuma
            if(this.$store.getters.selectedDates.length == 1 && this.$store.getters.selectedDates[0] != null && Object.prototype.hasOwnProperty.call(this.$store.getters.selectedDates[0], "id") && this.$store.getters.selectedDates[0].id == this.data.id) {
                return;
            }

            this.clicked = !this.clicked

            if(this.clicked) {
                this.$store.dispatch('ADD_DATE', this.data)    
            }
            else{
                this.$store.dispatch('REMOVE_DATE', this.data)
            }
            
            setTimeout(() => {
                //window.console.log(this.$store.getters.selectedDates);
            }, 500)

        },

        getRippleColor() {
            return hexToRgba(this.data.color, 0.3)
        },

        getRippleData() {
            return {
                color: this.data.color,
                startingOpacity: 0.9,
                easing: 'ease-in'
            }
        },

        checkIfAlreadySelected() {
            let vm = this
            this.$store.getters.selectedDates.forEach(date => {
                if(date != null && Object.prototype.hasOwnProperty.call(date, "id") ) {
                        if(date.id === vm.data.id) {
                        vm.clicked = true;
                    }
                }
            })
        }
    },

    created() {
        
        //window.console.log("### DateItem.vue ###")
        //window.console.log(this.data);
    },

    mounted() {
        this.checkIfAlreadySelected()
        emitter.on('TRUNCATE_SELECTED_DATES', e => {
            this.clicked = false
            if(this.$store.getters.selectedDates.length == 0) {
                this.$store.dispatch('ADD_DATE', this.$store.getters.dates[0])
                this.checkIfAlreadySelected()
            }
        })
    },

    unmounted() {
        
    }
}

</script>

<style scoped>
    .date_item {
        height:80px;
        width: 100%;
        border-right:1px solid #8E8E8E;
        border-top:1px solid #8E8E8E;
        border-bottom:1px solid #8E8E8E;
        border-left: 5px solid #E67171;
    }

    .date_item_content {
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        height:100% !important;
    }

    .date_item_title {
        width:100%;
        text-align:center;
        padding-top:30px;
        font-size:20px;
        font-weight:600;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    .category_item_name {
        width:100%;
        text-align:center;
        padding-top:5px;
        font-size:16px;
        font-weight:500;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }
</style>